function tabEvent( elem ) {
	const state= {
		currentElem: [],
		target: {}
	}

	const current= state.currentElem
			,	target= state.target;

	_jh.each(elem, function(i,v) {
		const idx= this.dataset.tabIndex;
		if( !(idx in target) ) target[idx]= [];
		target[idx].push(this);
		if( this.classList.contains('selected') ) current.push(this);

		// if( this.dataset.tabRoute !== undefined ) {
			this.addEventListener('click', function(e) {
				e.stopPropagation();

				_jh.each(current, function (key, val) {
					if( this.dataset.tabHeight !== undefined ) this.style.maxHeight= '0';
					this.classList.remove('selected');
				});
				state.currentElem= [];

				if( state.currentIndex === this.dataset.tabIndex ) {
					state.currentIndex= null;
					return;
				}
				
				state.currentIndex= this.dataset.tabIndex;
				_jh.each(target[idx], function (key, val) {
					if( this.dataset.tabHeight !== undefined ) this.style.maxHeight= this.scrollHeight + 'px';
					this.classList.remove('hide');
					this.classList.add('selected');
					current.push(this);
				});

			});
		// }



	});

}

export const tab_event= tabEvent;