// import $ from 'jQuery';
export default (function() {
	var container= $('#container'),
		$IndexClass, wrapper;
	function MainClass() {};
	$.extend( MainClass.prototype, {
		init: function(node) {
			$IndexClass= jhc.Class.IndexClass;
			this.node= node,
			this.Html= $('html'),
			this.body= $('body'),
			this.document= $(document),
			this.wininnerHeight,
			this.scrollArea= $('#scroll-area'),
			this.scrollBtn= document.getElementsByClassName('scroll-btn'),
			this.insteadmenu= $('.insteadmenu'),
			this.target, 
			this.targetUrl,
			this.menuIcon= $('#menu-icon'),
			this.scrollActive= this.node[0].getElementsByClassName('scroll-active'),
			this.sectElem= this.node.find('.section'),
			this.loadingArea= $('#section00'),
			this.loadingImg= this.loadingArea.find('.image-slide'),
			this.arrowArea= this.loadingArea.find('.slide-arrow-area'),
			this.mainbull= this.loadingArea.find('.main-bull'),
			this.sect00Bottom= this.loadingArea.find('.bottom'),
			this.sect00txtBox= this.sect00Bottom.find('.text-box'),
			this.txtList= this.sect00txtBox.find('li'),
			this.mainTxt= this.txtList.find('span'),
			this.txtListBull= this.sect00Bottom.find('.txt-bull'),
			this.txtEllipsis= document.getElementsByClassName('txt-ellipsis'),
			this.contentsLink= $('.contents-link'),
			this.pageLink= this.node.find('.page-link'),
			this.section04= document.getElementById('section04'),
			this.section01Wrap= $('#section01-wrap'),
			this.section05Wrap= $('#section05-wrap'),
			this.newsList= this.section05Wrap.find('.image-slide'),
			this.newsListBull= this.section05Wrap.find('.news-bull'),
			this.boardCont= this.section05Wrap.find('.main_news_list'),
			this.preEvtList= $('#event-pre-list'),
			this.preBoardList= $('#news-pre-list'),
			this.boardList= this.boardCont.find('.board_list'),
			this.boardBull= this.section05Wrap.find('.bullets'),
			
			this.formMailWrap= $('#form-mail-wrap'),
			this.formMail= $('#form-mail'),
			this.formMailData= this.formMail.find('.data'),
			this.formMailAlert= this.formMailWrap.find('.alert'),
			this.formMailSubmit= this.formMailWrap.find('.submit'),
			this.privatCheck= $('#join-private-agree'),
			
			this.GallerySlide,
			this.noticeDebounce= $.noticeFadeout();
		},
		run: function() {
			var that= this;
			$(window).off();
			$IndexClass.scrollClickEvt($(this.scrollBtn));
			$IndexClass.ellipsis(this.txtEllipsis);
			$IndexClass.rollingEvt(this.loadingImg, this.mainbull, true, true, this.arrowArea);
			console.dir(this.pageLink)
			$IndexClass.pageSelectEvt(this.pageLink);
			this.onLoad();
			this.formmailSubmit();
		},
		getNewsList: function() {
			var mainNewArea= document.getElementById('main--news-area');
			$IndexClass.getJson(mainNewArea, 4);
		},
		onLoad: function() {
			var that= this;
			document.onreadystatechange= function(){
				if(document.readyState === 'complete') {
					console.log('complete');
					that.gmapMain();
					// that.daumMap();
					that.onloadCallback()();
					return;
				}
			}
			if(document.readyState === 'complete') {
				console.log('ready');
				that.gmapMain();
				// that.daumMap();
				that.onloadCallback()();
			}
		},
		onloadCallback: function() {
			var that= this;
			return function() {
				$IndexClass.scrollEvtInstance.reSetElem(that.scrollActive);
				$IndexClass.scrollEvtInstance.reSet()();
			}
		},
		daumMap: function() {
			var dmap= document.getElementById('main-gmap'),
				map = new daum.maps.Map(dmap, daummap.options); //지도 생성 및 객체 리턴
			map.setZoomable(false); 

			new daum.maps.Marker({
			    map: map,
			    position: daummap.pos,
			    image: daummap.icon
			});
			new daum.maps.Marker({
			    map: map,
			    position: daummap.pos,
			    image: daummap.icon2
			});
		},
		gmapMain: function() {
			setTimeout( function () {

			var target= document.getElementById('main-gmap'),
				varInt= (window.innerWidth/-500000).toFixed(3),
				centerX= gmapObj.gmap.center.lng(),
				centerY= gmapObj.gmap.center.lat(),
				/*centerY= 37.484245,
				centerX= 126.899278,*/
				centeLng= window.innerWidth > 800 ? centerX + parseFloat(varInt) : centerX,
				option= Object.assign({}, gmapObj.option),
				markerOpt= Object.assign({}, gmapObj.markerOpt),
				markerOpt2= Object.assign({}, gmapObj.markerOpt2);

			option.center= {lat: centerY, lng: centeLng};
			if( target ){
				console.dir(target)
				
					var mgmap = new google.maps.Map(target, option);
						markerOpt.map= mgmap;
						markerOpt2.map= mgmap;
						new google.maps.Marker(markerOpt);
						new google.maps.Marker(markerOpt2);
			}
			
			}, 500);
		},
		
		
		trMaskEvt: function() {
			var that= this;
			this.transitionMask.addClass('on')
				.on( 'animationend webkitAnimationEnd', function() {
					$(this).removeClass('on');
					if(!that.modalArea.hasClass('on')) {
						that.modalWrap[0].innerHTML= '';
					}
				});
		},
		josaFilter: function( str ) {
			var code = str.charCodeAt(str.length-1) - 44032,
				josa= (code % 28 === 0) ? '를' : '을';
			return josa;
		},
		formmailLoop: function() {
			var that= this,
				loop= true,
				data= {};
			$.each( this.formMailData, function(i, v) {
				var name= this.name;
				if( this.value === '' ) {
					josa= that.josaFilter(this.title);
					that.noticeDebounce(that.formMailAlert, this.title + josa + ' 입력하지 않으셨습니다.');
					$(this).focus();
					loop= false;
					return false;
				}
				switch(name){
					case 'email':
						var regExp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
						if (this.value.match(regExp) === null) {
							that.noticeDebounce(that.formMailAlert, 'Please, check the Email address.');
							$(this).focus();
							loop= false;
							return false;
						}
						break;
				}
				data[name]= this.value;
			});
			return loop ? data : false;
		},
		formmailSubmit: function() {
			var that= this,
				obj, data;
			this.formMailSubmit.clickbindtouch(function(e) {
				if( !that.privatCheck[0].checked ) {
					that.noticeDebounce(that.formMailAlert, '개인정보 제공에 동의하지 않았습니다.');
					return;
				}
				data= that.formmailLoop();
				if(!data) { return; }
				obj= {
					url: 'formmail.php',
					formdata: data,
					syncprint: false,
					complete: function() {
						that.noticeDebounce(that.formMailAlert, obj.result);
					}
				}
				that.formMailAlert.AjaxPrint(obj);
			});
		}
	});

	// jhc.Class.Main= new MainClass();
	return new MainClass();
}());
